@import 'css/helpers';

.section {
  position: relative;
  // margin-top: 72px;
  margin-bottom: -120px;

  @include respond-to('tablet') {
    margin-bottom: 0;
  }

  background-image: url('/images/homepage/insideview.png');
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: center;

  // &:before {
  //   content: "";
  //   display: inline-block;
  //   position: absolute;
  //   width: 100%;
  //   height: 60%;
  //   margin-top: 10%;
  //   background-image: linear-gradient(
  //     rgba(0, 0, 0, 1.0) 0%,
  //     rgba(0, 0, 0, 0) 20%,
  //     rgba(0, 0, 0, 0)  100%
  //   );
  // };

  // &:after {
  //   content: "";
  //   display: inline-block;
  //   position: absolute;
  //   width: 100%;
  //   height: 60%;
  //   margin-top: 10%;
  //   background-image: linear-gradient(
  //     rgba(0, 0, 0, 0.0) 0%,
  //     rgba(0, 0, 0, 0.5) 80%,
  //     rgba(0, 0, 0, 1.0)  100%
  //   );
  // };
}

.comingSoon {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 800;
  border-radius: 20px;
  text-shadow: 2px 2px 2px black;
  font-family: Fasttrack, sans-serif;
  background: linear-gradient(to right, #e4667b, #ff02b3);

  /* pinkish gradient */
  box-shadow: 3px 2px 2px 0px black;

  /* reddish border */
  padding-top: 5px;
  line-height: 70px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  transition: all 0.3s ease;

  /* Smooth transition for hover effects */
  position: relative;

  /* Needed for absolute positioning of the pseudo-element */
  overflow: hidden;

  /* Ensures the shimmer effect is contained within the border radius */
}

.comingSoon:hover {
  color: #fff;

  /* Changes text color on hover */
  transform: scale(1.1);

  /* Scales up the text slightly on hover */
  cursor: pointer;

  /* Changes the cursor to a pointer to indicate it's clickable */
}

.comingSoon::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;

  /* Start from the left */
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgb(255 255 255 / 0.4),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  to {
    left: 150%;
  }
}

.comingSoon:hover::before {
  background: linear-gradient(
    120deg,
    transparent,
    rgb(255 255 255 / 0.8),
    transparent
  );
}

.pin {
  height: 100vh;
  width: 100%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  --perspective: 100px;

  perspective: var(--perspective);
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.top,
.bottom {
  height: 50%;
  width: 100%;
}

.top {
  transform-origin: center;
  transform: rotate(180deg);
}

.title {
  perspective: var(--perspective);
  line-height: 1;
  font-family: Quickstarter, sans-serif;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-shadow: 2px 4px 10px black;

  & > * {
    transform-style: preserve-3d;
    will-change: transform;
  }
}

.subtitle {
  font-size: 24px;
  letter-spacing: 4px;
  line-height: 32px;
  text-shadow: 4px 4px 10px black;
  font-family: FastTrack, sans-serif;
  color: #f3f3f3;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.subtitle-inner {
  padding-left: 8px;
  padding-right: 8px;
}

.base-logo {
  filter: drop-shadow(4px 4px 3px rgb(0 0 0 / 0.9));
}

.cta {
  margin-top: 16px;
  transform-style: preserve-3d;
}
